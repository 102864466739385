<template>
  <section class="pt-4 px-0 px-xl-4">
    <b-container fluid>
      <b-row class="mb-3">
        <b-col>
          <b-card>
            <b-button @click="enableNotif">Enable Notif</b-button>
            <div class="text-right">
              <h6>Mostra su base</h6>
              <b-button-group>
                <b-button
                  :variant="chartView === 'month' ? 'primary' : 'secondary'"
                  @click="setViewMonth"
                  >mensile
                </b-button>
                <b-button
                  :variant="chartView === 'year' ? 'primary' : 'secondary'"
                  @click="setViewYear"
                  >annua
                </b-button>
              </b-button-group>
              <doughnut-chart
                :data="chartData"
                :options="options"
                :height="500"
              ></doughnut-chart>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
import DoughnutChart from "../components/DoughnutChart.vue";

navigator.serviceWorker.register("sw.js");

export default {
  components: {
    DoughnutChart
  },
  name: "Dashboard",
  data() {
    return {
      chartData: {
        labels: ["e-commerce", "negozio"],
        datasets: [
          {
            label: "Comparazione vendite e-commerce/negozio",
            data: [],
            backgroundColor: ["rgb(255, 153, 0)", "rgb(54, 162, 235)"],
            hoverOffset: 4
          }
        ]
      },
      options: {
        response: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            title: function(tooltipItem, chartData) {
              return chartData.labels[tooltipItem[0].index];
            },
            label: function(tooltipItem, chartData) {
              return chartData.datasets[0].data[
                tooltipItem.index
              ].toLocaleString(this.lang, {
                style: "currency",
                currency: "EUR",
                currencyDisplay: "symbol"
              });
            },
            afterLabel: function(tooltipItem, chartData) {
              const percent = Math.round(
                (chartData.datasets[0].data[tooltipItem.index] /
                  chartData.datasets[0].data.reduce(function(a, b) {
                    return a + b;
                  }, 0)) *
                  100
              );
              return "(" + percent + "%)";
            }
          }
        }
      },
      chartView: "month"
    };
  },
  beforeMount() {
    this.setChart();
  },
  methods: {
    enableNotif: function() {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          // get service worker
          navigator.serviceWorker.ready.then(sw => {
            // subscribe
            sw.pushManager
              .subscribe({
                userVisibleOnly: true,
                applicationServerKey:
                  process.env.VUE_APP_PUSH_NOTIFICATION_PUBLIC_KEY
              })
              .then(subscription => {
                console.log(JSON.stringify(subscription));
                this.storeSubscription(subscription);
              });
          });
        }
      });
    },
    storeSubscription: function(subscription) {
      this.$http
        .put(`${process.env.VUE_APP_API_ENDPOINT}notification/subscription`, {
          subscription: JSON.stringify(subscription)
        })
        .then(res => {
          console.log(res.data);
        })
        .catch(err => {
          this.error = err;
        });
    },
    sendData: function() {
      this.$http
        .post(`${process.env.VUE_APP_API_ENDPOINT}user`, this.form)
        .then(res => {
          this.registered = res.data.success;
        })
        .catch(err => {
          this.error = err;
        });
    },
    setChart: function() {
      this.step = 1;
      this.$http
        .get(
          `${process.env.VUE_APP_API_ENDPOINT}chart/sales/comparison/${this.chartView}`
        )
        .then(res => {
          this.chartData.datasets[0].data = res.data;
        })
        .catch(err => {
          this.error = err;
        });
    },
    setViewMonth: function() {
      this.chartView = "month";
      this.setChart();
    },
    setViewYear: function() {
      this.chartView = "year";
      this.setChart();
    }
  }
};
</script>
