<script>
import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,
  props: ["data", "options"],
  mounted() {
    this.renderDoughnutChart();
  },
  computed: {
    datasets: function() {
      let result = [];
      this.data.datasets.forEach(function(element) {
        result.push(element.data);
      });
      //result.push(this.data.labels);
      return result;
    }
  },
  methods: {
    renderDoughnutChart: function() {
      this.renderChart(this.data, this.options);
    }
  },
  watch: {
    datasets: function() {
      this.renderDoughnutChart();
    }
  }
};
</script>
